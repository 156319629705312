<template>
  <div class="pt-[84px]  pl-6 pr-2.5 w-full h-screen overflow-scroll bg-[#F7F7FC]">
    <div class="grid_box_shadow !bg-white w-full  bg-opacity-80 rounded-lg">
        <div class="flex flex-col flex-grow h-full  border-[#ECEEF5]">
          <transition >
            <div>
                <div class="flex px-3 py-3.5 items-center justify-center bg-white">
                  <div class="w-full flex justify-start items-center gap-x-2 ">
                    <AnalyticsTypeV2 v-model="selected_analytic_type"></AnalyticsTypeV2>
                    <SearchField
                      id="search-workspace"
                      v-model="analytics_search"
                      type="text"
                      label="Search Brand or Campaign"
                      iconName="lock.svg"
                      customClass="h-8 bg-white with_border"
                    >
                      <template v-slot:right_btn>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 17 18" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.95166 8.22033C2.95166 5.32428 5.29938 2.97656 8.19543 2.97656C11.0915 2.97656 13.4392 5.32428 13.4392 8.22033C13.4392 9.6277 12.8848 10.9056 11.9824 11.8475C11.9532 11.8708 11.9249 11.896 11.8979 11.923C11.8708 11.9501 11.8457 11.9783 11.8224 12.0075C10.8805 12.9097 9.6027 13.4641 8.19543 13.4641C5.29938 13.4641 2.95166 11.1164 2.95166 8.22033ZM12.5616 14.0009C11.3475 14.9193 9.83508 15.4641 8.19543 15.4641C4.19481 15.4641 0.95166 12.221 0.95166 8.22033C0.95166 4.21971 4.19481 0.976562 8.19543 0.976562C12.1961 0.976562 15.4392 4.21971 15.4392 8.22033C15.4392 9.86011 14.8943 11.3726 13.9758 12.5867L16.7071 15.3181C17.0977 15.7086 17.0977 16.3418 16.7071 16.7323C16.3166 17.1228 15.6835 17.1228 15.2929 16.7323L12.5616 14.0009Z" fill="#757A8A"/>
                        </svg>
                      </template>
                    </SearchField>
                  </div>
                  <div class="w-full flex justify-center items-center">
                    <p class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none">Analytics</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z" fill="#3C4549"/>
                    </svg>
                  </div>
                  <div class="w-full flex items-center justify-end">
                    <AnalyticsToggleBtnV2></AnalyticsToggleBtnV2>
                  </div>
                </div>
                  <table class="ana_table table_height_links text-left flex-1 w-full ">
                  <thead class="bg-[#F4F6FA] text-[#757A8A] font-semibold text-[0.875rem] font-poppins">
                    <tr class="flex items-center">
                      <td v-if="selected_analytic_type === analytic_types.LINK" class="py-3 pl-3 w-[12.5%]">Campaign / Links</td>
                      <td v-if="selected_analytic_type === analytic_types.BIO_LINK" class="py-3 w-[12.5%] pl-3">Brand / Links</td>
                      <td v-if="selected_analytic_type === analytic_types.LINK" class="py-3 w-[12.5%] text-center ">Total Links</td>
                      <td v-if="selected_analytic_type === analytic_types.BIO_LINK" class="py-3 w-[12.5%] text-center "></td>
                      <td class="py-3 w-[12.5%] text-center">Clicks</td>
                      <td class="py-3 w-[12.5%] text-center">Unique Clicks</td>
                      <td class="py-3 w-[12.5%] text-center">Conversions</td>
                      <td class="py-3 w-[12.5%] text-center"> Conv. rate</td>
                      <td class="py-3 w-[12.5%] text-center">Created</td>
                      <td v-if="selected_analytic_type === analytic_types.LINK" class="py-3 w-[12.5%] pr-3 text-right">See Stats</td>
                      <td v-if="selected_analytic_type === analytic_types.BIO_LINK" class="py-3 w-[12.5%] pr-3 text-right"></td>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr class="py-3 w-full text-center" v-if="brandsLoader">
                      <div class=" text-center items-center py-4">
                        <clip-loader
                          :size="size"
                          :color="color"
                        ></clip-loader>
                      </div>
                    </tr>
                    <tr class="py-3 w-full text-center" v-else-if="selected_analytic_type === analytic_types.LINK && overview.campaigns.length === 0">
                      <p class="no-rows" >
                        <i class="fab fa-bandcamp"
                           aria-hidden="true"></i>{{ getFilteredMessage('brand/campaigns', analytics_search) }}
                      </p>
                    </tr>
                    <tr v-else>
                      <div id="accordion" v-if="selected_analytic_type === analytic_types.LINK">
                        <!--loop here...........-->
                        <div class="top_child child_item panel !h-full" v-for="(cta,cta_index) in overview.campaigns" v-if="selected_analytic_type === analytic_types.LINK && overview.campaigns.length > 0">
                          <AnalyticsCampaignSectionV2
                                :brand_id="getWorkspace._id"
                                :campaign="cta"
                                :campaign_index="cta_index"
                                @update="fetchCampaignAnalytics($event)"
                              ></AnalyticsCampaignSectionV2>
                              <div :id="cta._id" class="collapse child_row last_child">
                                <!--loop here...........-->
                                <div v-if="cta.loader" class="py-4">
                                <clip-loader
                                  :size="size"
                                  :color="color"
                                ></clip-loader>
                                </div>
                                  <template v-else>
                                  <template v-if="cta.links && cta.links.data">
                                    <div class="child_item " v-for="link in cta.links.data">
                                      <AnalyticsLinkSectionV2
                                        :link="link"
                                        :campaign="cta"
                                        :brand="getWorkspace._id"
                                      ></AnalyticsLinkSectionV2>
                                    </div>
                                

<!--                                    table Pagination New Design-->

                                   <div class="py-[0.78rem]">
                                    <template>
                                            <div class="flex justify-center items-center" v-if="cta.total_links > cta.links.per_page">
                                              <TablePagination
                                                :clickedMethod="paginate"
                                                :resultCount="cta.total_links"
                                                :data="{current_page: cta.links.current_page, brand_id: getWorkspace._id, cta_id: cta._id, cta_idex: cta_index, cta: cta}"
                                                :itemsPerPage="cta.links.per_page"
                                                :currentPage="cta.links.current_page"
                                                :totalPagesNumber="cta.links.last_page"
                                              ></TablePagination>
                                            </div>
                                      </template>
                                    </div>

                                  </template>
                                </template>
                              </div>
                            </div>
                      </div>
                      <BioAnalyticsLinksSectionV2 :bio_links="overview.bio_links" v-if="selected_analytic_type === analytic_types.BIO_LINK"></BioAnalyticsLinksSectionV2>
                    </tr>
                  </tbody>
                </table>
            </div>
          </transition>
        </div>
    </div>
  </div>
</template>
<style lang="less">
.ana_table {
  tbody {
    display: block;
    overflow-y: auto;
    table-layout: fixed;
  }

  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.table_height_links {
  tbody {
    height: calc(100vh - 15.2rem);
  }
}
</style>
<script>
import {mapActions, mapGetters} from 'vuex'
import {DARK_PURPLE_LOADER_COLOR, ANALYTIC_TYPE} from '@/common/constants'

export default ({
  'name': 'Analytics',
  components: {
    AnalyticsTypeV2:() => import('../AnalyticsTypeV2.vue'),
    SearchField: () => import('@/ui/ui-kit/v2/SearchField.vue'),
    AnalyticsToggleBtnV2: () => import('../AnalyticsToggleBtnV2.vue'),
    AnalyticsCampaignSectionV2: () => import('./AnalyticsCampaignSectionV2.vue'),
    AnalyticsLinkSectionV2: () => import('./AnalyticsLinkSectionV2.vue'),
    BioAnalyticsLinksSectionV2: () => import('./BioAnalyticsLinksSectionV2.vue'),
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue')
  },
  data () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      analytics_search: '',
      isPermission: false,
      overview: [],
      brandsLoader: true,
      selected_analytic_type: ANALYTIC_TYPE.LINK,
      analytic_types: ANALYTIC_TYPE
    }
  },
  async created() {
    this.analytics_search = ''
    await this.getListStats()
  },
  mounted() {
    if (window.localStorage.getItem('access_password_protected') === 'true') {
      this.isPermission = true
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspace'
    ]),
    filteredBrandsList() {
      if (this.analytics_search && this.analytics_search.trim().length > 0) {
        let filteredArray = this.overview.filter(item => {
          if (item.name.toLowerCase().includes(this.analytics_search.toLowerCase())) {
            return true
          }

          let campaignSearched = (item.campaigns.filter(campaign => campaign.name.toLowerCase().includes(this.analytics_search.toLowerCase())))
          return (campaignSearched.length > 0)
        })
        return filteredArray
      } else {
        return this.overview
      }
    }
  },
  methods: {
    ...mapActions([
      'listViewStatistics',
      'fetchBrandListAnalytics',
      'fetchCampaignListAnalytics',
      'fetchBioLinkListAnalytics'
    ]),
    async paginate(page, data) {
    await this.fetchCampaignByLinks(page, data.brand_id, data.cta_id, data.cta_index, data.cta)
    },
    async getListStats() {
      this.brandsLoader = true
      await this.listViewStatistics().then(val => {
        this.overview = val
        this.brandsLoader = false
        this.fetchBioLinkAnalytics()
      })
    },

    fetchCampaignAnalytics() {
      this.overview.campaigns.forEach((campaign) => {
        this.fetchCampaignListAnalytics(campaign._id).then(val => {
          campaign.fetchStats = true
          campaign.total_visitors = val.aggregations.total_visitors.value
          campaign.unique_visitors = val.aggregations.unique_clicks.value
          campaign.total_clicks_and_conversions = val.aggregations.clicked_conversions.doc_count
          if (campaign.total_visitors <= 0) {
            campaign.conversion_rate = 0
          } else {
            const digits = 2
            let value = (campaign.total_clicks_and_conversions / campaign.total_visitors) * 100
            value = value * Math.pow(10, digits)
            value = Math.round(value)
            campaign.conversion_rate = value / Math.pow(10, digits)
          }
        })
      })
    },
    fetchBioLinkAnalytics() {
      this.overview.bio_links.forEach((bio) => {
          this.fetchBioLinkListAnalytics(bio._id).then(res => {
            bio.bioFetchStats = true
            bio.total_hits = res.hits.total
            bio.total_visitors = res.hits.total
            bio.unique_visitors = res.aggregations.unique_clicks.value
          })
      })
    },
    refresh() {
      this.getListStats()
    }
  },
  watch: {
    async 'getWorkspace._id' (value) {
      await this.getListStats()
    }
  }
})
</script>
